.wrapper {
    .card {
      max-width: 400px;
      min-width: 400px;
    }

    width: 100%;
    display: flex;
    justify-content: center;
}

