.theme {
  > ul {
    > li {
      > .link {
        &:hover {
          color: #00d1b2 !important;
        }
      }

      > ul > li > a {
        &:hover {
            color: #00d1b2;
        }
      }
    }
  }
  background-color: rgba(237, 242, 249, 0.96);
}
