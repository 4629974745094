.view {
  overflow: hidden;
  max-height: 100vh;
}

.wrapper {
  padding-top: 54px;
}

.sidebar {
  padding: 16px;
  background-color: rgba(237, 242, 249, 0.96);
  min-height: 100vh;
  max-height: 100vh;
  overflow: scroll;
}

.main {
  min-height: 100vh;
  max-height: 100vh;
  overflow: scroll;
}
