.operator {
  gap: 16px;
}

.logo-seasy {
  vertical-align: middle;
}

// .navbar-item {
//   background: url(../../images/logo-seasy.jpeg) no-repeat center center;
//   background-size: cover;
//   width: 30px;
// }
